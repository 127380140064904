import Portfolio from "./components/Portfolio";
import "./CSS/App.css";

function App() {
  return (
    <div className="App">
        <Portfolio />
    </div>
  );
}

export default App;
